<template>
  <div ref="reference" :class="['sp-landing-tab-anchor']" style="width: 100%">
    <Tabs
      v-if="tabList && tabList.length"
      ref="tabHeaderRef"
      v-model="activeValue"
      :stretch="false"
      :class="['gt-tabs', 'sp-landing-tab-anchor__header', { 'is-sticky': true }]"
      :style="`top: ${stickyTop - 1}px`"
      @tab-click="handleClick"
    >
      <TabPane
        v-for="(item, index) in tabList"
        :key="index"
        :label="Object.values(item.title)[0]"
        :name="`${index}-${item.pageUrl}`"
        :lazy="false"
        :data-url="item.pageUrl"
      >
        <template slot="label">
          <div v-if="item.unselectBackground && item.selectBackground" class="tab-img-box">
            <img
              class="default-img"
              :src="item.unselectBackground + '?x-oss-process=image/format,webp'"
              alt=""
            />
            <img
              class="selected-img"
              :src="item.selectBackground + '?x-oss-process=image/format,webp'"
              alt=""
            />
          </div>

          <span v-else>{{ Object.values(item.title)[0] }}</span>
        </template>
      </TabPane>
    </Tabs>
    <!-- .swiper-no-swiping用来阻止滑动的 -->
    <div ref="contentRef" class="sp-landing-tab-anchor__content">
      <!-- <GtSwiper
        ref="gtSwiperRef"
        :class="['sp-landing-tab-swipper', { 'swiper-no-swiping': !isMobile }]"
        :data="tabList"
        :swiper-option="swiperOption"
        :hidden-button="true"
      >
        <template slot="default" slot-scope="{ data: subData, index }">
          <div v-observe-resize class="sp-landing-tab-swipper-item">
            <slot :subData="subData" :index="index"></slot>
          </div>
        </template>
      </GtSwiper> -->
      <template v-for="(tabItem, index) in tabList">
        <div :key="index" v-dom-hook="resizeOption" class="sp-landing-tab-anchor__item">
          <div
            :id="tabItem.pointerId"
            :data-page-url="tabItem.pageUrl"
            :data-page-index="index"
            class="sp-landing-tab-anchor__anchor"
          ></div>
          <slot :subData="tabItem" :index="index"></slot>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { useLandingPage } from '@speedshop/sdk-api';
import { componentsCommon } from '@speedshop/template';
import { Tabs, TabPane } from 'element-ui';
// import { throttle } from 'lodash';
// import { getScrollTop, scrollToTopW } from '@/utils/dom';

export default {
  components: {
    Tabs,
    TabPane
    // GtSwiper
  },
  mixins: [componentsCommon],
  props: {
    data: {
      type: Object,
      requirede: true
    }
  },
  data() {
    return {
      activeValue: this.data.content.list && `0-${this.data.content.list[0].pageUrl}`,
      fetched: false,
      tabList: [],
      reloadAnchorRefs: [],
      reloadAnchorObserve: undefined,
      resizeObserver: undefined,
      stickyTop: 94,
      tabBarHeight: 49,
      resizeOption: {
        bind: el => {
          this.observeResizeHandle(el);
        },
        unbind: () => {
          this.resizeObserver.disconnect();
        }
      }
    };
  },
  computed: {
    // isSticky() {
    //   return this.data?.content?.widgetItem?.widgetCode === 'widget-tab-sticky-to-top';
    // },
    operationData() {
      return this.data && this.data.operationData;
    }
    // swiperOption() {
    //   const self = this;
    //   return {
    //     slidesPerView: 1,
    //     autoHeight: true,
    //     observer: true,
    //     observeParents: true,
    //     // observeSlideChildren: true,
    //     spaceBetween: (this.isMobile ? 8 : 48) * 2,
    //     // autoplay: false,
    //     // loop: true,
    //     // allowSlidePrev: false,
    //     // allowSlideNext: false,
    //     // uniqueNavElements: false,
    //     // pagination: false,
    //     // navigation: false,
    //     on: {
    //       slideChangeTransitionEnd() {
    //         const item = self.data.content.list[this.activeIndex];
    //         self.activeValue = item.pageUrl;
    //         self.$emit('tabSwitch', this.activeIndex);
    //         self.tabBackToTop();
    //       }
    //     }
    //   };
    // }
  },
  // created() {
  // },
  mounted() {
    console.log('TabAnchor', this.data);
    // const lazyHandleScroll = throttle(this.handleScroll, 1000);
    // window.addEventListener('scroll', lazyHandleScroll, true);
    // this.$on('hook:beforeDestroy', () => {
    //   window.removeEventListener('scroll', lazyHandleScroll, true);
    // });
    this.getTabData();
    this.getTop();

    this.$eventBus.$on('close-mobile-marquree', () => {
      this.getTop();
    });
    window.addEventListener('scroll', this.scrollHandle, { passive: true });
    if (window.location.hash) {
      setTimeout(() => {
        this.scrollToTabItem(window.location.hash);
      }, 800);
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollHandle);
  },
  methods: {
    getTop() {
      const headerEl =
        document.querySelector('.mobile_header-layer') || document.querySelector('.gt-header');
      this.stickyTop = headerEl?.getBoundingClientRect()?.height || 66;
      // const firstItem = this.$refs.reference.querySelector('.sp-landing-tab-anchor__item');
      // this.stickyBottom = firstItem?.getBoundingClientRect()?.top;
      this.tabBarHeight =
        this.$refs.reference
          .querySelector('.sp-landing-tab-anchor__header')
          ?.getBoundingClientRect()?.height || 49;
      return (
        (
          document.querySelector('.mobile_header-layer') || document.querySelector('.gt-header')
        )?.getBoundingClientRect()?.height || 66
      );
    },
    scrollHandle(e) {
      this.getTop();
      const targetPostion = this.stickyTop + this.tabBarHeight;
      const range = [targetPostion, targetPostion + 200];
      const items = Array.from(
        this.$refs.reference.querySelectorAll('.sp-landing-tab-anchor__anchor')
      ).map(el => ({ rect: el.getBoundingClientRect(), el }));
      for (let i = 0; i < items.length; i++) {
        const { rect, el } = items[i];
        if (rect.top >= range[0] && rect.top <= range[1]) {
          const pageUrl = el.getAttribute('data-page-url');
          const index = el.getAttribute('data-page-index');
          this.activeValue = `${index}-${pageUrl}`;
          history.replaceState(null, null, `#${el.id}`);
        }
        if (
          rect.top < range[0] &&
          items[i + 1] &&
          items[i + 1].rect.top >= document.documentElement.clientHeight
        ) {
          const pageUrl = el.getAttribute('data-page-url');
          const index = el.getAttribute('data-page-index');
          this.activeValue = `${index}-${pageUrl}`;
          history.replaceState(null, null, `#${el.id}`);
        }
      }
      // const scrollDom = document.querySelector('html');
      // const container = this.$refs.contentRef;
      // console.log('updateSize', getScrollTop(), container.getBoundingClientRect().top);
      // if (container.getBoundingClientRect().top) {
      // }
      // this.$refs.gtSwiperRef?.swiper?.update();
    },
    async getTabData() {
      const LandingPage = useLandingPage();
      const list = (this.data && this.data.content.list) || [];

      const fetchList = list.map(async item => {
        const { landingData } = await LandingPage.init({
          pageUrl: item.pageUrl,
          deviceType: this.isMobile ? 'h5' : 'pc',
          preview: this.$route.query.preview === 'true',
          cacheFlag: this.$route.query.preview !== 'true'
        });
        item.list = landingData;
      });
      await Promise.all(fetchList);
      this.fetched = true;
      this.tabList = list;
      setTimeout(() => {
        this.lazyLoad();
      }, 500);
    },
    // handleHardClick() {},
    handleClick({ name }) {
      const index = this.tabList.findIndex((it, id) => `${id}-${it.pageUrl}` === name);
      const item = this.tabList[index];
      history.replaceState(null, null, `#${item.pointerId}`);
      this.scrollToTabItem(`#${item.pointerId}`);
    },
    scrollToTabItem(hash) {
      if (!hash.startsWith('#') || hash.length < 2) return;
      const el = this.$refs.reference.querySelector(hash);
      // console.log('scrollToTabItem', hash, el);
      if (!el) return;
      window.scrollTo({
        top:
          this.$store.state.pageScrollY +
          el.getBoundingClientRect().top -
          this.stickyTop -
          this.tabBarHeight -
          30,
        left: 0,
        behavior: 'smooth'
      });
    },
    observeResizeHandle(el) {
      if (!el) return;
      if (!this.resizeObserver) {
        this.resizeObserver = new ResizeObserver(
          entries => {
            requestAnimationFrame(() => {
              entries.forEach((entry, index) => {
                if (entry.intersectionRatio <= 0) return;
                this.$refs.gtSwiperRef?.swiper?.updateAutoHeight();
              });
            });
          },
          {
            rootMargin: '-100px'
          }
        );
      }
      this.resizeObserver.observe(el);
    },
    lazyLoad() {
      const images = document.querySelectorAll('img[data-src]');
      images.forEach(image => {
        if (this.isInViewport(image)) {
          image.src = image.getAttribute('data-src');
          image.removeAttribute('data-src');
        }
      });
    },
    isInViewport(element) {
      const rect = element.getBoundingClientRect();

      return (
        rect.bottom > 0 &&
        rect.top < (window.innerHeight || document.documentElement?.clientHeight) + 200
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.sp-landing-tab-anchor__content {
}
// .sp-landing-tab-anchor__item {
//   // box-shadow: 0 0 20px rgba($color: #000000, $alpha: 0.4);
//   &:not(:last-child) {
//     margin-bottom: 40px;
//   }
// }
</style>
<style lang="scss">
.sp-landing-tab-anchor {
  .gt-tabs.el-tabs {
    // .el-tabs__nav-wrap.is-scrollable {
    //   padding: 0;
    // }
    .el-tabs__nav-wrap.is-scrollable {
      padding: 0 7.5vw;
    }
  }
}
.sp-landing-tab-anchor__item {
  // box-shadow: 0 0 20px rgba($color: #000000, $alpha: 0.4);
  // > .sp-landingpage-collection > .sp-row {
  //   padding: 0 7.5%;
  // }
  &:not(:last-child) {
    margin-bottom: 40px;
  }
}
.page-layout_mobile {
  .sp-landing-tab-anchor__item {
    > .sp-landingpage-collection > .sp-row {
      padding: 0 20px;
    }
    // box-shadow: 0 0 20px rgba($color: #000000, $alpha: 0.4);
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .sp-landing-tab-anchor {
    .gt-tabs.el-tabs {
      // .el-tabs__nav-wrap.is-scrollable {
      //   padding: 0;
      // }
      .el-tabs__nav-scroll {
        overflow-x: auto;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      .el-tabs__nav-wrap.is-scrollable {
        padding: 0 20px;
      }
    }
  }
}
.sp-row:has(
    .row-box
      > .sp-row-wrapper
      > .sp-landingpage-collection
      > .sp-row
      > .row-box
      > .sp-row-wrapper
      .sp-landing-tab-anchor
  ) {
  padding: unset;
}

.sp-landing-tab-anchor__header {
  // position: sticky;
  // top: 94px;
  // z-index: 99;
  // padding: 12px 0px;
  // margin: 20px 0px;
  // border: 1px solid red;
  // .el-tabs__header {
  //   margin: 0;
  // }
}
// 只多列的情况下对，对列中的sp-landing-tab-content生效，否则swiper会导致左右遮盖
// .sp-row-wrapper:not(:only-child) .sp-landing-swiper-tab .sp-landing-tab-content {
//   overflow: hidden;
// }
// .sp-landing-tab-swipper > .swiper-wrapper > .swiper-slide {
//   overflow: visible;
// }
// .sp-landing-tab-anchor__content .sp-landingpage-collection:not([data-landing-level='1']) > .sp-row {
//   margin: 0 auto;
//   padding: unset;
// }
</style>
